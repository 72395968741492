import { fetchApi } from "@/utils/apiCall"

/**
 * Header Links for Search Page
 */
export const getHeaderLinks = async (locale) => {
  try{
    const response = await fetch(`${process.env.NEXT_PUBLIC_CMS_STRAPI_STATIC_PAGE}header-footer?locale=${locale === 'ar' ? 'ar-SA' : 'en'}`)
    const res = await response.json()
    return res?.navigationLinks
  }
  catch(error){
    return null
  }
}
export const getHeaderFooterLinks = async (locale) => {
  try{
    const response = await fetch(`${process.env.NEXT_PUBLIC_CMS_STRAPI_STATIC_PAGE}header-footer?locale=${locale === 'ar' ? 'ar-SA' : 'en'}`)
    return await response.json()
  }
  catch(error){
    return null
  }
}

export const getWalletHelpSupport = async (lang) => {
  try {
    const response = await fetchApi({
      lang,
      baseUrl: process.env.NEXT_PUBLIC_API_URL_V1,
      subUrl: `get-wallet-help-support`,
      method: 'GET',
    })
    if (response?.data) {
      return response?.data
    }
    return null
  } catch (err) {
    return null
  }
}

export const isAuctionLiveCount = async () => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_AUCTION_URL}auction-count`)
    const response = await res.json()
    return [response?.data?.liveCount, response?.data?.upcomingCount]
  } catch (error) {
    return null
  }
}
