import Script from 'next/script'
// Google Analytics Tracking Script

export const initGA = () => (
  <Script
    id='google-tag-manager'
    strategy='afterInteractive'
    onLoad={() => {
      // Initialize Google Analytics
      window.ga = window.ga || function () {
        (window.ga.q = window.ga.q || []).push(arguments);
      };
      window.ga.l = +new Date();
      window.ga('create', `${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`, 'auto');
      window.ga('send', 'pageview');
    }}
    src='https://www.google-analytics.com/analytics.js'
  />
);